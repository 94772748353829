import React from 'react';
import {navigate} from 'gatsby';
import {AppState, Auth0Provider} from '@auth0/auth0-react';

import './retourenmanager/styles/site.css';

const onRedirectCallback = (appState: AppState | undefined) => navigate(appState?.returnTo || '/');

export const wrapRootElement: React.FC<any> = ({element}) => {
    return (
        <Auth0Provider domain={process.env.GATSBY_AUTH0_DOMAIN as string}
                       clientId={process.env.GATSBY_AUTH0_CLIENT_ID as string}
                       audience={process.env.GATSBY_AUTH0_AUDIENCE}
                       scope={process.env.GATSBY_AUTH0_SCOPE}
                       redirectUri={window.location.origin}
                       onRedirectCallback={onRedirectCallback}>
            {element}
        </Auth0Provider>
    );
};